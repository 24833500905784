import { Controller } from 'stimulus'
import { application } from '../../frontend/controllers/index'

export default class RateChangesController extends Controller {
  static get targets() {
    return ['authoriser', 'rateId', 'rejectionReason', 'secureHash', 'encryptedId', 'setLevelEscalation']
  }

  submit() {
    const rateId = this.rateIdTarget.value
    const rateAuthoriserId = this.authoriserTarget.value
    const secureHash = this.secureHashTarget.value
    const encryptedId = this.encryptedIdTarget.value
    const reasonForRejection = this.rejectionReasonTarget.value
    const setLevelEscalation = this.setLevelEscalationTarget.value === 'true'
    let authoriserKey = 'rate_authoriser_id'

    if (setLevelEscalation) {
      authoriserKey = 'user_authoriser_id'
    }

    if (reasonForRejection.length <= 0) {
      document.querySelector('.modal__validation--rejection-reason').style.display = 'block'
    } else {
      const url = `/rate_changes/${rateId}/reject_rate_request?rate_change%5B${authoriserKey}%5D=${rateAuthoriserId}&rate_change%5Breason_for_rejection%5D=${reasonForRejection}&rate_change%5Bsecure_hash%5D=${secureHash}&rate_change%5Bencrypted_id%5D=${encryptedId}`

      fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }).then(() => {
        window.location.href = url
      })
    }
  }
}
application.register('rate_changes', RateChangesController)
