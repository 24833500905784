/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import { ApolloProvider, useMutation, useQuery } from '@apollo/client'
import { DefaultButton, FormGrid, FormGroup, FormLabel, FormInput, Loading } from '@patchworkhealth/web-components'
import moment from 'moment'
import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Slide, ToastContainer, toast } from 'react-toastify'
import styled from 'styled-components'
import { errorToast } from '../../components/Containers/BlockBookings/helpers/BlockBookingsHelpers'
import createApolloClient from '../createApolloClient'
import DeContract, { AgencyProposalCard, AgencyProposalFooter } from './deContract'
import {
  AGENCY_WORKER_PROPOSAL_ACCEPT,
  AGENCY_WORKER_PROPOSALS,
  DE_CONTRACTS,
  AGENCY_WORKER_PROPOSAL_REJECT,
  UPDATE_PROPOSAL_SHORTLISTING_STATUS,
  AGENCY_WORKER_DOCS_EXPORT_DOCUMENTATIONS_UPLOADS,
} from './graphql'
import { ChevronRightIcon, DOC_TYPES, DownloadDocumentIcon, ExportDocsIcon } from './proposalIcons'
import RateDetails from './ratesTable'

export const ellipsis = (str, maxLength = 35) => {
  if (str.length <= maxLength) {
    return str
  }
  return `${str.slice(0, maxLength)}...`
}

function DownloadIcon() {
  return (
    <svg
      className="h-5 w-5 text-blue-1"
      fill="none"
      height="24"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
      <polyline points="7 10 12 15 17 10" />
      <line x1="12" x2="12" y1="15" y2="3" />
    </svg>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const client = createApolloClient()
  const config = { attributes: true, childList: true, subtree: true, characterData: true }

  const observer = new MutationObserver(() => {
    const rootElement = document.querySelector('[data-react-component="agency_proposals"]')
    const shiftId = rootElement?.getAttribute('shiftId')
    const usesMspShortlisting = rootElement?.getAttribute('usesMspShortlisting')
    const canShortlistProposals = rootElement?.getAttribute('canShortlistProposals')
    const usesDeContracts = rootElement?.getAttribute('usesDeContracts')
    const organisation = rootElement?.getAttribute('organisation')
    const requiresAgencyCompliance = rootElement?.getAttribute('requiresAgencyCompliance')

    if (rootElement && rootElement.childElementCount === 0) {
      ReactDOM.render(
        <>
          <ToastContainer position="top-center" transition={Slide} />
          <ApolloProvider client={client}>
            <AgencyProposals
              canShortlistProposals={canShortlistProposals === 'true'}
              organisation={organisation}
              requiresAgencyCompliance={requiresAgencyCompliance === 'true'}
              shiftId={shiftId}
              usesDeContracts={usesDeContracts === 'true'}
              usesMspShortlisting={usesMspShortlisting === 'true'}
            />
          </ApolloProvider>
        </>,
        rootElement
      )
    }
  })
  observer.observe(document, config)
})

const initialInputs = {
  contractOptions: [],
  selectedProposal: null,
  deProposal: null,
  selectedContract: null,
  loading: false,
  showRejection: null,
  rejectionReason: '',
}

function AgencyProposals({
  shiftId,
  usesMspShortlisting,
  canShortlistProposals,
  usesDeContracts,
  requiresAgencyCompliance,
}) {
  const [inputs, setInputs] = useState(initialInputs)
  const [activeTabs, setActiveTabs] = useState({})

  const setActiveTab = (proposalId, tab) => {
    setActiveTabs((prevTabs) => ({
      ...prevTabs,
      [proposalId]: tab,
    }))
  }

  /* Sidebar ----------------------------------------------- */

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState(null)

  const sidebarRef = useRef(null)

  console.log('selectedDocument', selectedDocument)

  const openSidebar = (document) => {
    setSelectedDocument(document)
    setSidebarOpen(true)
  }

  const closeSidebar = () => {
    setSelectedDocument(null)
    setSidebarOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeSidebar()
      }
    }

    if (sidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [sidebarOpen])

  /* Graphql ----------------------------------------------- */

  const { data, loading, error, refetch } = useQuery(AGENCY_WORKER_PROPOSALS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !shiftId,
    variables: {
      id: Number(shiftId),
    },
  })

  // Fetch DE Contracts
  const { loading: loading2 } = useQuery(DE_CONTRACTS, {
    skip: !usesDeContracts,
    variables: {
      status: 1,
    },
    onCompleted: (res) => {
      setInputs({
        ...inputs,
        contractOptions: res.deContractsContracts.list,
      })
    },
  })

  const [acceptProposal] = useMutation(AGENCY_WORKER_PROPOSAL_ACCEPT)
  const [rejectProposal] = useMutation(AGENCY_WORKER_PROPOSAL_REJECT)
  const [toggleShortlisting] = useMutation(UPDATE_PROPOSAL_SHORTLISTING_STATUS)

  const [exportDocsMutation] = useMutation(AGENCY_WORKER_DOCS_EXPORT_DOCUMENTATIONS_UPLOADS)

  /* Functions --------------------------------------------- */

  const handleAcceptText = (proposal) => {
    // 1. Check if Worker is direct_engagement, if not we return accept, else we continue
    // 2. IF de, we then loop through contract options and see if there are any paymentsTypes that match worker

    if (!usesDeContracts || proposal?.agencyWorker?.employmentType !== 'direct_engagement') {
      return 'Accept'
    }

    if (proposal.agencyWorker.employmentType === 'direct_engagement') {
      const paymentType =
        proposal.agencyWorker.paymentType === 'paye'
          ? 'de_paye'
          : proposal.agencyWorker.isSoleTrader
          ? 'de_sole_trader'
          : 'de_ltd'
      const contractOptionsFiltered = inputs.contractOptions.filter((i) => paymentType === i.contractType)

      if (contractOptionsFiltered.length === 0) {
        return 'Accept, No Contracts'
      }

      return 'Attach DE Contract'
    }

    return 'Accept'
  }

  const handleRejectProposal = (proposal) => {
    if (inputs.showRejection === proposal.id) {
      setInputs({
        ...inputs,
        showRejection: null,
        rejectionReason: '',
      })
    } else {
      setInputs({
        ...inputs,
        showRejection: proposal.id,
        rejectionReason: '',
      })
    }
  }

  const handleRejectConfirm = async (proposal) => {
    try {
      const { data: result } = await rejectProposal({
        variables: {
          id: Number(proposal?.id),
          reasonForRejection: inputs.rejectionReason,
        },
      })

      const errors = result?.agencyWorkerProposalDecline?.errors

      if (errors.length > 0) {
        toast.update(2, { ...errorToast, render: errors[0].message })
        return
      }

      alert('Proposal Rejected')
      refetch()
    } catch (err) {
      alert('Error', err)
    }
  }

  const handleAcceptProposal = async (proposal) => {
    const check = handleAcceptText(proposal)

    if (check === 'Attach DE Contract') {
      setInputs({
        ...inputs,
        deProposal: proposal,
      })
      return
    }

    setInputs({
      ...inputs,
      loading: true,
    })

    try {
      const { data: result } = await acceptProposal({
        variables: {
          id: Number(proposal?.id),
        },
      })

      setInputs({
        ...inputs,
        loading: false,
      })

      const errors = result?.agencyWorkerProposalAccept.errors
      if (errors.length > 0) {
        window.alert(`${errors[0].message}`)
        return
      }

      window.alert('Proposal Accepted!')

      refetch()
      window.location.reload()
    } catch (err) {
      window.alert('There was an Error!')
    }
  }

  const handleAssignContract = async (proposal) => {
    const findOption = inputs.contractOptions.find((option) => option.id === inputs.selectedContract.value)

    setInputs({
      ...inputs,
      loading: true,
    })
    try {
      const { data: result } = await acceptProposal({
        variables: {
          id: Number(proposal?.id),
          deContractVersionId: Number(findOption?.activeVersion?.id),
          staffGroupId: Number(proposal.agencyWorker.staffGroup.idValue),
        },
      })

      setInputs({
        ...inputs,
        loading: false,
      })

      const errors = result?.agencyWorkerProposalAccept.errors
      if (errors.length > 0) {
        window.alert(`${errors[0].message}`)
        return
      }
      window.alert('Proposal Accepted!')
      window.location.reload()
    } catch (err) {
      window.alert('There was an Error!')
    }
  }

  const handleShortlistProposal = async (proposal) => {
    try {
      const { data: result } = await toggleShortlisting({
        variables: {
          id: Number(proposal?.id),
          className: 'AgencyWorkerProposal',
        },
      })

      const errors = result?.updateProposalShortlistingStatus.errors

      if (errors.length > 0) {
        alert('Error', errors[0].message)
        return
      }
      alert('Shortlist toggled')

      refetch()
    } catch (err) {
      alert('Error', err)
    }
  }

  const handlePaymentType = (paymentType, isSoleTrader) => {
    if (paymentType === 'paye') {
      return 'Paye'
    }

    if (paymentType === 'umbrella_company') {
      return 'Umbrella'
    }

    if (paymentType === 'ltd' && isSoleTrader) {
      return 'Sole Trader'
    }

    return 'Ltd'
  }

  const handleExportDocs = async (proposal) => {
    console.log('Handle some export stuff', proposal)

    try {
      const { data: result } = await exportDocsMutation({
        variables: {
          agencyRegistrationId: Number(proposal.agencyWorker.id),
        },
      })

      const errors = result?.agencyWorkerDocsExportDocumentationsUploads?.errors
      if (errors.length > 0) {
        window.alert(`${errors[0].message}`)
        return
      }

      if (result?.agencyWorkerDocsExportDocumentationsUploads?.success) {
        const downloadLink = `data:application/zip;base64,${result?.agencyWorkerDocsExportDocumentationsUploads?.zipString}`
        const link = document.createElement('a')
        link.href = downloadLink
        link.download = 'documents.zip'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        alert('Error exporting documents. Please try again.')
      }
    } catch (err) {
      window.alert('There was an Error!')
    }
  }

  /* Render ----------------------------------------------- */

  if (loading || loading2 || inputs.loading)
    return (
      <div>
        <Loading />
      </div>
    )
  if (error) return <div>Error...</div>

  // if canShortlistProposals if false, we want to filter out proposals where shortlistedAt is not null
  let filteredProposals = data?.shift?.agencyWorkerProposals?.nodes ?? []

  if (!canShortlistProposals && usesMspShortlisting) {
    filteredProposals = data?.shift?.agencyWorkerProposals?.nodes?.filter((proposal) => proposal.shortlistedAt !== null)
  }

  console.log('selectedDocument', selectedDocument)

  // TODO - Conditionally render new/old worker docs

  return (
    <AgencyProposalStyled>
      <h1>Agency Proposals</h1>
      <p>
        The following agency workers have been proposed for <strong>Shift #{shiftId}</strong>
      </p>

      <hr />
      {filteredProposals?.map((proposal) => {
        const totalComparatorRateIncVat = proposal.rates.reduce((acc, rate) => {
          return acc + rate.comparatorRateIncVat * rate.hours
        }, 0)

        const { showVatRegistered } =
          (proposal.agencyWorker.employmentType === 'direct_engagement' &&
            proposal.agencyWorker.paymentType === 'ltd') ||
          proposal.agencyWorker.isSoleTrader
            ? { showVatRegistered: true }
            : { showVatRegistered: false }

        const isComparator =
          proposal.agencyWorker.employmentType === 'direct_engagement' && proposal.agencyWorker.paymentType !== 'ltd'

        const activeTab = activeTabs[proposal.id] || '1'

        if (inputs.deProposal && inputs.deProposal.id === proposal.id) {
          return (
            <AgencyProposalCard>
              <p style={{ fontWeight: 'bold', fontSize: 18 }}>
                {proposal.agencyWorker.firstName} {proposal.agencyWorker.lastName}
              </p>
              <DeContract
                handleAssignContract={handleAssignContract}
                inputs={inputs}
                proposal={proposal}
                setInputs={setInputs}
              />

              <AgencyPaymentTypes>
                <p style={{ background: 'green' }}>{proposal.agencyWorker.staffGroup.title}</p>
                <p>{proposal.agencyWorker.employmentType === 'non_direct_engagement' ? 'Non DE' : 'DE'}</p>
                <p>{handlePaymentType(proposal.agencyWorker.paymentType, proposal.agencyWorker.isSoleTrader)}</p>
                {showVatRegistered && <p>VAT registered: {proposal?.agencyWorker?.isVatRegistered ? 'Yes' : 'No'}</p>}
              </AgencyPaymentTypes>
            </AgencyProposalCard>
          )
        }
        if (!inputs.deProposal) {
          return (
            <AgencyProposalCard>
              <p style={{ fontWeight: 'bold', fontSize: 18 }}>
                {proposal.agencyWorker.firstName} {proposal.agencyWorker.lastName}
              </p>

              <FormGrid columns={3}>
                <FormGroup>
                  <FormLabel>Agency</FormLabel>
                  <p>{proposal.agencyUser.agency.title}</p>
                </FormGroup>
                <FormGroup>
                  <FormLabel>Grade</FormLabel>
                  <p>{proposal.agencyWorker.grade.title}</p>
                </FormGroup>
                <FormGroup>
                  <FormLabel>Email</FormLabel>
                  <p>{proposal.agencyWorker.email}</p>
                </FormGroup>
                {/* {!requiresAgencyCompliance && ( */}
                <>
                  <FormGroup>
                    <FormLabel>Compliance Pack</FormLabel>
                    <a
                      href={`${window.location.origin}${proposal.agencyWorker.compliancePackUrl}`}
                      rel="noopener noreferrer"
                      style={{ display: 'block', textDecoration: 'underline' }}
                      target="_blank"
                    >
                      Download
                    </a>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>CV</FormLabel>
                    <a
                      href={`${window.location.origin}${proposal.agencyWorker?.cvUrl}`}
                      rel="noopener noreferrer"
                      style={{ display: 'block', textDecoration: 'underline' }}
                      target="_blank"
                    >
                      Download
                    </a>
                  </FormGroup>
                </>
                {/* )} */}

                <FormGroup>
                  <FormLabel>Total{isComparator ? ' Comparator' : ' Charge'} Rate (inc. VAT):</FormLabel>
                  <p>£{totalComparatorRateIncVat.toFixed(2)}</p>
                </FormGroup>
              </FormGrid>

              <AgencyPaymentTypes>
                <p style={{ background: 'green' }}>{proposal.agencyWorker.staffGroup.title}</p>
                <p>{proposal.agencyWorker.employmentType === 'non_direct_engagement' ? 'Non DE' : 'DE'}</p>
                <p>{handlePaymentType(proposal.agencyWorker.paymentType, proposal.agencyWorker.isSoleTrader)}</p>
                {showVatRegistered && <p>VAT registered: {proposal?.agencyWorker?.isVatRegistered ? 'Yes' : 'No'}</p>}
              </AgencyPaymentTypes>

              {/* TODO - requiresAgencyCompliance === true then show new docs, else show old */}
              {requiresAgencyCompliance && (
                <div style={{ marginTop: '20px' }}>
                  <button
                    onClick={() => setActiveTab(proposal.id, '1')}
                    style={{
                      background: 'none',
                      border: 'none',
                      marginRight: 10,
                      borderBottom: activeTab === '1' ? '2px solid rgb(102 102 102)' : '2px transparent',
                    }}
                    type="button"
                  >
                    Rate Card Breakdown
                  </button>

                  {proposal.agencyWorkerDocs.length > 0 && (
                    <button
                      onClick={() => setActiveTab(proposal.id, '2')}
                      style={{
                        background: 'none',
                        border: 'none',
                        borderBottom: activeTab === '2' ? '2px solid rgb(102 102 102)' : '2px transparent',
                      }}
                      type="button"
                    >
                      Compliance documents
                    </button>
                  )}
                </div>
              )}

              {activeTab === '1' && <RateDetails proposal={proposal} />}
              {activeTab === '2' && (
                <div
                  style={{
                    height: 'auto',
                    overflow: 'auto',
                    borderBottom: 'none',
                    fontSize: 12,

                    marginTop: '20px',
                    padding: 0,
                  }}
                >
                  {proposal.agencyWorkerDocs.map((doc) => {
                    return (
                      <StyledDocRow onClick={() => openSidebar(doc)}>
                        <div>{DOC_TYPES.filter((i) => i.formatName === doc.categoryType)[0]?.name}</div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {doc.content && doc.content['Expiry date'] && (
                            <p style={{ color: 'red', margin: 0 }}>
                              Expiry date: {moment(doc.content['Expiry date']).format('DD/MM/YYYY')}
                            </p>
                          )}

                          <button style={{ border: 0, background: 'none' }} type="button">
                            <ChevronRightIcon />
                          </button>
                        </div>
                      </StyledDocRow>
                    )
                  })}
                </div>
              )}

              {sidebarOpen && (
                <Sidebar ref={sidebarRef}>
                  <SidebarHeader>
                    <h2>
                      {selectedDocument
                        ? ellipsis(
                            DOC_TYPES.filter((i) => i.formatName === selectedDocument.categoryType)[0]?.name ?? '-',
                            20
                          )
                        : ''}
                    </h2>
                    <button onClick={closeSidebar} type="button">
                      <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path
                          clipRule="evenodd"
                          d="M18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L12 10.5858L6.70711 5.29289C6.31658 4.90237 5.68342 4.90237 5.29289 5.29289C4.90237 5.68342 4.90237 6.31658 5.29289 6.70711L10.5858 12L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L12 13.4142L17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L13.4142 12L18.7071 6.70711Z"
                          fill="#7C7D80"
                          fillRule="evenodd"
                        />
                      </svg>
                    </button>
                  </SidebarHeader>
                  <SidebarBody>
                    {selectedDocument && (
                      <div>
                        {selectedDocument.categoryType !== 'dbs_number' &&
                          selectedDocument.categoryType !== 'face_to_face_interview_conducted' && (
                            <UploadedDoc>
                              <p
                                style={{
                                  marginBottom: 48,
                                }}
                              >
                                {ellipsis(
                                  DOC_TYPES.filter((i) => i.formatName === selectedDocument.categoryType)[0]?.name ??
                                    '-',
                                  20
                                )}
                              </p>
                              <svg
                                fill="none"
                                height="24"
                                style={{
                                  position: 'absolute',
                                  right: 12,
                                  top: 16,
                                }}
                                viewBox="0 0 24 24"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  clipRule="evenodd"
                                  d="M10.0166 3.21542C11.9096 2.78771 13.8902 2.9834 15.663 3.77329C16.1675 3.99807 16.7587 3.77134 16.9834 3.26687C17.2082 2.76239 16.9815 2.17122 16.477 1.94644C14.3103 0.981007 11.8896 0.741837 9.57581 1.2646C7.26206 1.78736 5.17929 3.04404 3.63811 4.84722C2.09693 6.6504 1.17992 8.90346 1.02384 11.2704C0.86777 13.6373 1.48099 15.9913 2.77206 17.9812C4.06312 19.9711 5.96285 21.4904 8.18792 22.3125C10.413 23.1345 12.8442 23.2153 15.1189 22.5427C17.3936 21.8702 19.39 20.4803 20.8103 18.5804C22.2306 16.6806 22.9986 14.3719 23 11.9999V11.0799C23 10.5276 22.5523 10.0799 22 10.0799C21.4477 10.0799 21 10.5276 21 11.0799V11.9993C20.9989 13.9401 20.3705 15.8285 19.2084 17.3829C18.0464 18.9374 16.413 20.0745 14.5518 20.6248C12.6907 21.1751 10.7015 21.109 8.88102 20.4364C7.06051 19.7638 5.50619 18.5208 4.44987 16.8927C3.39354 15.2645 2.89181 13.3386 3.01951 11.402C3.14721 9.4654 3.89749 7.62199 5.15845 6.14666C6.41942 4.67133 8.12351 3.64314 10.0166 3.21542ZM22.7075 4.70662C23.0978 4.3159 23.0975 3.68274 22.7068 3.29241C22.316 2.90208 21.6829 2.90239 21.2925 3.29311L11.9996 12.5953L9.70711 10.3028C9.31658 9.91224 8.68342 9.91224 8.29289 10.3028C7.90237 10.6933 7.90237 11.3264 8.29289 11.717L11.2929 14.717C11.4805 14.9046 11.7349 15.0099 12.0002 15.0099C12.2656 15.0098 12.52 14.9043 12.7075 14.7166L22.7075 4.70662Z"
                                  fill="#42D05B"
                                  fillRule="evenodd"
                                />
                              </svg>

                              <button
                                fullWidth
                                icon={DownloadIcon}
                                onClick={() => {
                                  const link = document.createElement('a')
                                  link.href = selectedDocument.fileUrls[0]
                                  link.target = '_blank'
                                  link.rel = 'noreferrer'
                                  link.click()
                                }}
                                style={{
                                  marginBottom: 4,
                                  position: 'absolute',
                                  bottom: 12,
                                  right: 12,
                                  left: 12,
                                  background: '#05405C',
                                  color: 'white',
                                  padding: 3,
                                  borderRadius: 7,
                                  fontWeight: 500,
                                }}
                                type="button"
                              >
                                Download Doc
                              </button>
                            </UploadedDoc>
                          )}

                        {Object.keys(selectedDocument.content).map((key) => (
                          <div key={key} style={{ marginBottom: 8 }}>
                            <label style={{ fontWeight: 'bold' }}>{key}</label>
                            <p style={{ marginTop: -2 }}>
                              {typeof selectedDocument.content[key] === 'boolean'
                                ? selectedDocument.content[key]
                                  ? 'Yes'
                                  : 'No'
                                : typeof selectedDocument.content[key] === 'string' &&
                                  moment(selectedDocument.content[key], moment.ISO_8601).isValid()
                                ? moment(selectedDocument.content[key]).format('Do MMMM YYYY')
                                : selectedDocument.content[key]}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </SidebarBody>
                </Sidebar>
              )}

              <AgencyProposalFooter>
                {/* Normal State  */}

                {inputs.showRejection !== proposal.id && (
                  <>
                    {requiresAgencyCompliance && <ExportDocsIcon handleClick={() => handleExportDocs(proposal)} />}
                    <div>
                      {usesMspShortlisting && canShortlistProposals && (
                        <DefaultButton
                          onClick={() => handleShortlistProposal(proposal)}
                          style={{ marginRight: 5 }}
                          type={proposal?.shortlistedAt === null ? 'white' : 'grey'}
                        >
                          {proposal?.shortlistedAt === null ? 'Shortlist' : 'Remove Shortlist'}
                        </DefaultButton>
                      )}

                      <DefaultButton
                        color="red"
                        onClick={() => handleRejectProposal(proposal)}
                        style={{ marginRight: 5 }}
                      >
                        Reject
                      </DefaultButton>

                      <DefaultButton onClick={() => handleAcceptProposal(proposal)}>
                        {handleAcceptText(proposal)}
                      </DefaultButton>
                    </div>
                  </>
                )}

                {/* Confirm Reject ----------------------------------- */}

                {inputs.showRejection === proposal.id && (
                  <>
                    <FormInput
                      name="122"
                      onChange={(e) => setInputs({ ...inputs, rejectionReason: e.target.value })}
                      placeholder="Reason for rejection"
                      style={{ width: 200 }}
                      type="text"
                    />
                    <div>
                      <DefaultButton
                        onClick={() => setInputs({ ...inputs, showRejection: null })}
                        style={{ marginRight: 5 }}
                        type="white"
                      >
                        Cancel
                      </DefaultButton>

                      <DefaultButton
                        color="red"
                        onClick={() => handleRejectConfirm(proposal)}
                        style={{ marginRight: 5 }}
                      >
                        Confirm
                      </DefaultButton>
                    </div>
                  </>
                )}
              </AgencyProposalFooter>
            </AgencyProposalCard>
          )
        }

        return null
      })}
    </AgencyProposalStyled>
  )
}

export default AgencyProposals

const AgencyProposalStyled = styled.div``
const AgencyPaymentTypes = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  line-height: 1;
  p {
    text-align: center;
    background: #127de9;
    color: white;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    margin-left: 4px;
    padding: 3px 12px 4px 12px;
  }
`

const Sidebar = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 500px; /* Adjust width as needed */
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
`

const SidebarHeader = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #ddd;

  * {
    margin: 0;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
`

const SidebarBody = styled.div`
  padding: 20px;
`

// docs ------------------------------------------------

const StyledDocRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 8px;
  background: white;
  height: 50px;
  border-bottom: 1px solid #ccc;
  font-size: 12px;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #f5f5f5;
  }
`

// Uploaded doc card -----------------------------------

const UploadedDoc = styled.div`
  position: relative;
  margin-bottom: 30px;
  height: 172px;
  width: 215px;
  border-radius: 8px;
  border: 1px solid #e4e7eb;
  padding: 18px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`
