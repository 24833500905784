/* eslint-disable no-alert */
/* eslint-disable no-inner-declarations */
import { Controller } from 'stimulus'
import { application } from '../../frontend/controllers/index'

export default class FraudDeclarationController extends Controller {
  static targets = ['fraudStatementRow']

  connect() {
    const fraudToggle = document.getElementById('uses_fraud_declaration_statement')

    this.toggleStatementRow(fraudToggle.checked) // Initially show/hide the statement row based on the toggle value
  }

  toggleStatement() {
    const fraudToggle = document.getElementById('uses_fraud_declaration_statement')

    this.toggleStatementRow(fraudToggle.checked)
  }

  toggleStatementRow(show) {
    const fraudStatementRow = this.fraudStatementRowTarget
    const fraudStatementInput = document.getElementById('fraud_declaration_statement')
    const svgIcon = fraudStatementRow.querySelector('svg')

    if (show) {
      fraudStatementRow.style.display = ''
      fraudStatementInput.style.background = ''
      fraudStatementInput.style.pointerEvents = ''
      svgIcon.style.display = 'none'
    } else {
      const statementValue = fraudStatementInput.value.trim()

      if (statementValue !== '') {
        fraudStatementRow.style.display = ''
        fraudStatementInput.style.background = '#ECECEC'
        fraudStatementInput.style.pointerEvents = 'none'
        svgIcon.style.display = ''
      } else {
        fraudStatementRow.style.display = 'none'
        fraudStatementInput.style.background = ''
        fraudStatementInput.style.pointerEvents = ''
        svgIcon.style.display = 'none'
      }
    }
  }
}

application.register('fraud_declaration', FraudDeclarationController)
