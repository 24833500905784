import { Controller } from 'stimulus'

import Sortable from 'sortablejs/modular/sortable.complete.esm.js';
import Ajax from '../../frontend/packs/ajax'

export default class BatchExportConfigsListController extends Controller {

  static get targets() {
    return ['deleteButton']
  }

  connect() {
    this.initSortable();
  }

  baseUrl() {
    console.log('Base URL is:',this.data.get('base-url'))
     return this.data.get('base-url')
         ? this.data.get('base-url')
         :  "/organisation_staff_groups/" + this.orgStaffGroupId() + "/batch_export_config";
  }

  deleteConfig(e) {
    var ajax = new Ajax();
    var configId = e.currentTarget.id.split('-')[1];
    var url = this.baseUrl() + "/remove_column";
    ajax.post(url, { column: { id: configId } }, (data) => {
      data = JSON.parse(data);
      if(data.success) {
        document.getElementById("batch_export_config_"+data.column_id).remove();
      }
    });
  }

  initSortable() {
    console.log('initSortable()');
    var sort = Sortable.create(this.data.element,
      {
        handle: ".handle",
        onUpdate: () => {
      		this.saveOrder();
      	}
      }
    )
  }

  saveOrder() {
    var order = this.deleteButtonTargets.map((e) => { return e.id.split('-')[1] } );
    var url = this.baseUrl() + '/reorder';
    var ajax = new Ajax();
    ajax.post(url, {order: order}, function() {});
  }

  orgStaffGroupId() {
    return this.data.get('org-staff-group-id');
  }
}

import { application } from '../../frontend/controllers/index'
application.register("batch_export_configs_list", BatchExportConfigsListController)
