/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-alert */
/* eslint-disable no-inner-declarations */
import queryString from 'query-string'
import { Controller } from 'stimulus'
import { application } from '../../frontend/controllers/index'

const placeholderHTML = `
<div id="placeholder-animation" class="ph-item">
  <div class="ph-col-2">
    <div class="ph-avatar"></div>
  </div>
  <div>
    <div class="ph-row">
      <div class="ph-col-4"></div>
      <div class="ph-col-8 empty"></div>
      <div class="ph-col-6"></div>
      <div class="ph-col-6 empty"></div>
      <div class="ph-col-2"></div>
      <div class="ph-col-10 empty"></div>
    </div>
  </div>
  <div class="ph-col-12">
    <div class="ph-picture"></div>
    <div class="ph-row">
      <div class="ph-col-10 big"></div>
      <div class="ph-col-2 empty big"></div>
      <div class="ph-col-4"></div>
      <div class="ph-col-8 empty"></div>
      <div class="ph-col-6"></div>
      <div class="ph-col-6 empty"></div>
      <div class="ph-col-12"></div>
    </div>
  </div>
  <div class="ph-col-4">
    <div class="ph-picture"></div>
  </div>
  <div>
    <div class="ph-row">
      <div class="ph-col-6"></div>
      <div class="ph-col-6 empty"></div>
      <div class="ph-col-2"></div>
      <div class="ph-col-10 empty"></div>
      <div class="ph-col-8"></div>
      <div class="ph-col-4 empty"></div>
      <div class="ph-col-12"></div>
    </div>
  </div>
</div>
`

export default class ModalController extends Controller {
  static get targets() {
    return [
      'container',
      'content',
      'getSingleShiftIds',
      'reasons',
      'getSplitShiftIds',
      'getSessionalShiftIds',
      'submitSuspendButton',
      'characters',
      'authToken',
      'submitNewShiftButton',
    ]
  }

  initialize() {
    this.isOpen = false
  }

  connect() {}

  disconnect() {}

  get actionBarController() {
    const action_bar_element = document.getElementById('action-bar')
    const action_bar = this.application.getControllerForElementAndIdentifier(action_bar_element, 'action_bar')
    return action_bar
  }

  escalationReasonOnChange() {
    const reasonsValidation = document.querySelector('.modal__rate-escalation-card--reasons')
    const reasonsLengthValidation = document.querySelector('.modal__rate-escalation-card--reasons-length')
    reasonsValidation.style.display = 'none'
    reasonsLengthValidation.style.display = 'none'

    this.charactersTarget.innerHTML = 254 - this.reasonsTarget.value.length
    if (this.reasonsTarget.value.length == 254) {
      event.preventDefault()
    }
  }

  shiftQuery(shiftType) {
    const shiftList = document.querySelector('.list')

    if (shiftList) {
      const checked = Array.from(shiftList.querySelectorAll('input[type=checkbox]:checked')).map((item) => item.value)
      const params = {}
      if (shiftType === 'receipt') {
        params['receipt_ids[]'] = checked
      } else {
        params['shift_ids[]'] = checked
      }
      return queryString.stringify(params)
    }
  }

  open(event) {
    // fix for z-index dropdowns
    const dropdown = document.getElementById('dropdown')
    if (dropdown) {
      dropdown.classList.add('z-auto')
      dropdown.classList.add('show-hard')
    }
    this.containerTarget.classList.add('show')
    this.containerTarget.style.display = 'block'
    document.body.classList.add('modal-open')
    document.getElementById('overlay').classList.add('modal-backdrop', 'fade', 'show')
    this.isOpen = true
    event.stopPropagation()

    if (this.data.get('source')) {
      const separator = this.data.get('source').includes('?') ? '&' : '?'
      const url = this.data.get('source') + separator + this.shiftQuery(this.data.get('shiftType'))
      fetch(url)
        .then((response) => {
          if (response.status !== 200) {
            return
          }

          response.text().then((data) => {
            this.connect()
            this.contentTarget.innerHTML = data
          })
        })
        .catch((err) => {
          console.log('Fetch Error', err)
        })
    }
  }

  close(event) {
    event.preventDefault()
    // fix for z-index dropdowns
    const dropdown = document.getElementById('dropdown')
    if (dropdown) {
      dropdown.classList.remove('z-auto')
      dropdown.classList.remove('show-hard')
    }
    this.containerTarget.classList.remove('show')
    this.containerTarget.style.display = 'none'
    document.body.classList.remove('modal-open')
    document.getElementById('overlay').classList.remove('modal-backdrop', 'fade', 'show')

    this.contentTarget.innerHTML = placeholderHTML

    this.isOpen = false
  }

  submitDepartmentForm(event) {
    event.preventDefault()
    const nameSelector = document.querySelector('#department-name')
    const emailSelector = document.querySelector('#department-email')
    const numberSelector = document.querySelector('#department-number')
    const costCentreSelector = document.querySelector('.cost-centres-selector')
    const siteSelector = document.querySelector('#new-department-site-selector')
    const cutOffSelector = document.querySelector('#department-cut-off')
    const agencyCutOffSelector = document.querySelector('#department-agency-cut-off')

    /// Validation boxes
    const nameSelectorVal = document.querySelector('.modal__validation--department-name')
    const emailSelectorVal = document.querySelector('.modal__validation--department-email')
    const numberSelectorVal = document.querySelector('.modal__validation--department-number')
    const costCentreSelectorVal = document.querySelector('.modal__validation--department-cost-centre')
    const siteSelectorVal = document.querySelector('.modal__validation--department-site')
    const agencyCutOffSelectorVal = document.querySelector('.modal__validation--department-cut-off-agency')
    const cutOffSelectorVal = document.querySelector('.modal__validation--department-cut-off-alert')

    if (nameSelector && nameSelector.value === '') {
      event.preventDefault()
      nameSelectorVal.style.display = 'block'
      return
    }
    if (nameSelectorVal) {
      nameSelectorVal.style.display = 'none'
    }

    if (emailSelector && emailSelector.value === '') {
      event.preventDefault()
      emailSelectorVal.style.display = 'block'
      return
    }
    if (emailSelectorVal) {
      emailSelectorVal.style.display = 'none'
    }

    if (numberSelector && numberSelector.value === '') {
      event.preventDefault()
      numberSelectorVal.style.display = 'block'
      return
    }
    if (numberSelectorVal) {
      numberSelectorVal.style.display = 'none'
    }

    if (siteSelector && siteSelector.value === '') {
      event.preventDefault()
      siteSelectorVal.style.display = 'block'
      return
    }
    if (siteSelectorVal) {
      siteSelectorVal.style.display = 'none'
    }

    if (costCentreSelector && costCentreSelector.value === '') {
      event.preventDefault()
      costCentreSelectorVal.style.display = 'block'
      return
    }
    if (costCentreSelectorVal) {
      costCentreSelectorVal.style.display = 'none'
    }

    if (cutOffSelector && cutOffSelector.value === '') {
      event.preventDefault()
      cutOffSelectorVal.style.display = 'block'
      return
    }
    if (cutOffSelectorVal) {
      cutOffSelectorVal.style.display = 'none'
    }

    if (agencyCutOffSelector && agencyCutOffSelector.value === '') {
      event.preventDefault()
      agencyCutOffSelectorVal.style.display = 'block'
      return
    }
    if (agencyCutOffSelectorVal) {
      agencyCutOffSelectorVal.style.display = 'none'
    }

    document.getElementById('department-form').submit()
  }

  submitNewShift(event) {
    // disable create shift button on press, stop double press
    this.submitNewShiftButtonTarget.disabled = true
    const shiftForm = document.getElementById('shift-form')
    const siteSelector = document.querySelector('.sites-selector')
    const departmentSelector = document.querySelector('.department-selector')
    const reasonForBooking = document.querySelector('#reason-selector')
    const staffSelector = document.querySelector('#staff-selector')
    const gradeSelector = document.querySelector('#grade-selector')
    const workerSelector = document.querySelector('#worker-name-selector')
    const datePicker = document.querySelector('.flatpickr-input')
    const amountSelector = document.querySelector('#amount-selector')
    const startTime = document.querySelector('#start-time')
    const endTime = document.querySelector('#end-time')
    const rateInput = document.querySelector('#rate-input')
    const sessionalButton = document.querySelector('#sessional-shift-button')
    //
    const onCallStaffSelector = document.querySelector('#staff-group-id-on-call')

    const onCallButton = document.querySelector('#on-call-shift-button')
    const onCallStartDate = document.querySelector('#on-call-start-date')
    const onCallStartTime = document.querySelector('#on-call-start-time')
    const onCallEndDate = document.querySelector('#on-call-end-date')
    const onCallEndTime = document.querySelector('#on-call-end-time')
    const validationCollection = [
      siteSelector,
      departmentSelector,
      reasonForBooking,
      staffSelector,
      gradeSelector,
      datePicker,
    ]

    /// Validation boxes
    const siteSelectorVal = document.querySelector('.modal__validation--sites')
    const departmentSelectorVal = document.querySelector('.modal__validation--department')
    const reasonForBookingVal = document.querySelector('.modal__validation--reason')
    const staffSelectorVal = document.querySelector('.modal__validation--staff')
    const gradeSelectorVal = document.querySelector('.modal__validation--grade')
    const dateSelectorVal = document.querySelector('.modal__validation--date')
    const onCallDateVal = document.querySelector('.modal__validation--date-on-call')
    const amountSelectorVal = document.querySelector('.modal__validation--amount')
    const rateSelectorVal = document.querySelector('.modal__validation--rate')
    //
    const onCallStaffSelectorVal = document.querySelector('.modal__validation--staff-on-call')

    // function to reenable which has been added to the validation
    const enabledShiftButton = () => {
      this.submitNewShiftButtonTarget.disabled = false
    }

    if (siteSelector.value === '') {
      event.preventDefault()
      siteSelectorVal.style.display = 'block'
      enabledShiftButton()
      return
    }

    if (departmentSelector.value === '') {
      event.preventDefault()
      departmentSelectorVal.style.display = 'block'
      enabledShiftButton()
      return
    }

    if (reasonForBooking.value === '') {
      event.preventDefault()
      reasonForBookingVal.style.display = 'block'
      enabledShiftButton()
      return
    }

    if (onCallButton.checked && onCallStaffSelector.value === '') {
      event.preventDefault()
      onCallStaffSelectorVal.classList.remove('d-none')
      enabledShiftButton()
      return
    }

    if (!onCallButton.checked && staffSelector.value === '') {
      event.preventDefault()
      staffSelectorVal.style.display = 'block'
      enabledShiftButton()
      return
    }

    if (gradeSelector.value === '') {
      event.preventDefault()
      gradeSelectorVal.style.display = 'block'
      enabledShiftButton()
      return
    }

    if (amountSelector.value <= 0) {
      event.preventDefault()
      amountSelectorVal.style.display = 'block'
      enabledShiftButton()
      return
    }

    if (startTime.value === '' && !onCallButton.checked) {
      event.preventDefault()
      dateSelectorVal.style.display = 'block'
      enabledShiftButton()
      return
    }

    if (endTime.value === '' && !onCallButton.checked) {
      event.preventDefault()
      dateSelectorVal.style.display = 'block'
      enabledShiftButton()
      return
    }

    if (workerSelector.value === 'Select worker') {
      workerSelector.value = ''
    }

    if (
      sessionalButton.checked === true &&
      document.getElementById('rate-checker').value === '1' &&
      rateInput.value === ''
    ) {
      event.preventDefault()
      rateSelectorVal.style.display = 'block'
      enabledShiftButton()
      return
    }

    if (
      onCallButton.checked &&
      (onCallStartDate.value === '' ||
        onCallStartTime.value === '' ||
        onCallEndDate.value === '' ||
        onCallEndTime.value === '')
    ) {
      event.preventDefault()
      onCallDateVal.style.display = 'block'
      enabledShiftButton()
      return
    }

    if (onCallButton.checked) {
      // Function to format date and time strings into a valid JavaScript Date object
      function parseDateTime(dateString, timeString) {
        const [day, month, year] = dateString.split('/')
        const [hours, minutes] = timeString.split(':')
        // Note: Months in JavaScript's Date object are zero-based (0 - 11)
        return new Date(year, month - 1, day, hours, minutes)
      }

      // Parse the start date and time strings
      const startDateTime = parseDateTime(onCallStartDate.value, onCallStartTime.value)
      // Parse the end date and time strings
      const endDateTime = parseDateTime(onCallEndDate.value, onCallEndTime.value)

      // Compare the end date and time with the start date and time
      if (endDateTime <= startDateTime) {
        alert('End date must be after Start date')
        enabledShiftButton()
        return
      }
    }

    if (!shiftForm.checkValidity()) {
      const tmpSubmit = document.createElement('button')
      shiftForm.appendChild(tmpSubmit)
      tmpSubmit.click()
      shiftForm.removeChild(tmpSubmit)
      enabledShiftButton()
    } else {
      document.getElementById('shift-form').submit()
    }
  }

  submitNewAgencyLocum() {
    event.preventDefault()
    const newAgencyLocumForm = document.querySelector('#agency-locum-form')
    const editAgencyLocumForm = document.querySelector('.edit-agency-locum-form')

    const firstNameSelector = document.getElementById('agency_locum[first_name]')
    const firstNameVal = document.querySelector('.modal__validation--first-name')
    const secondNameSelector = document.getElementById('agency_locum[last_name]')
    const secondNameVal = document.querySelector('.modal__validation--second-name')
    const regBodySelector = document.getElementById('agency_locum[reg_body_number]')
    const regBodyVal = document.querySelector('.modal__validation--reg-body')

    const cvUpload = document.getElementById('agency_locum[cv]')
    const existingCvUpload = document.getElementsByClassName('cv__underline')
    const gradeSelector = document.getElementById('grade-selector')
    const gradeVal = document.querySelector('.modal__validation--grade')
    const agencySelector = document.getElementById('agency-selection')
    const agencyVal = document.querySelector('.modal__validation--agency')
    const staffSelector = document.getElementById('staff-selector')
    const staffSelectorVal = document.querySelector('.modal__validation--staff')

    if (cvUpload.value.length && existingCvUpload.length > 0) {
      if (confirm('This will override your existing CV Upload.\n Are you sure?') == false) {
        cvUpload.nextElementSibling.innerHTML = ''
        cvUpload.value = ''

        return false
      }
    }

    if (staffSelector.value === '') {
      event.preventDefault()
      staffSelectorVal.style.display = 'block'
    } else {
      staffSelectorVal.style.display = 'none'
    }

    if (firstNameSelector.value === '') {
      event.preventDefault()
      firstNameVal.style.display = 'block'
    } else {
      firstNameVal.style.display = 'none'
    }

    if (secondNameSelector.value === '') {
      event.preventDefault()
      secondNameVal.style.display = 'block'
    } else {
      secondNameVal.style.display = 'none'
    }

    if (regBodySelector.value === '' || regBodySelector.value.length > 10) {
      event.preventDefault()
      regBodyVal.style.display = 'block'
    } else {
      regBodyVal.style.display = 'none'
    }

    if (gradeSelector.value === '') {
      event.preventDefault()
      gradeVal.style.display = 'block'
    } else {
      gradeVal.style.display = 'none'
    }

    if (agencySelector.value === '') {
      event.preventDefault()
      agencyVal.style.display = 'block'
    } else {
      agencyVal.style.display = 'none'
    }

    if (
      firstNameSelector.value === '' ||
      secondNameSelector.value === '' ||
      regBodySelector.value === '' ||
      regBodySelector.value.length > 10 ||
      gradeSelector.value === '' ||
      staffSelectorVal.value === '' ||
      agencySelector.value === ''
    ) {
    } else {
      newAgencyLocumForm ? newAgencyLocumForm.submit() : editAgencyLocumForm.submit()
    }
  }

  submitRateEscalationRequest(event) {
    event.preventDefault()
    const target = event.target || event.srcElement

    const reasons = this.reasonsTarget
    const reasonsValidation = document.querySelector('.modal__rate-escalation-card--reasons')
    const reasonsLengthValidation = document.querySelector('.modal__rate-escalation-card--reasons-length')
    const rateEscHiddenInput = document.querySelector('.rate-esc-hidden')
    const authorizerPermission = document.querySelector('.modal__validation--permissions')
    const approvalPermission = document.querySelector('.modal__validation--approval-permissions')
    const selfAuthorization = document.querySelector('.self-approval')
    const approvalOnBehalf = document.querySelector('.approval-on-behalf')
    const approvalOnBehalfBox = document.querySelector('.rate-approval-on-behalf')

    // if reasons exists is empty, provide validation
    if (reasons.value.length <= 0) {
      event.preventDefault()
      reasonsLengthValidation.style.display = 'none'
      reasonsValidation.style.display = 'block'
    } else if (reasons.value.length > 254) {
      event.preventDefault()
      reasonsValidation.style.display = 'none'
      reasonsLengthValidation.style.display = 'block'
    } else if (reasonsValidation && reasonsLengthValidation) {
      reasonsValidation.style.display = 'none'
      reasonsLengthValidation.style.display = 'none'
    }

    /* Old Code

       else {
      reasonsValidation.style.display = "none";
      if (reasonsLengthValidation !== null) {
        reasonsLengthValidation.style.display = "none";
      }
    }


    */

    // if self authorisation isn't selected and no escalator selected, provide validation
    if (rateEscHiddenInput.value === '' && selfAuthorization.checked === false) {
      event.preventDefault()
      authorizerPermission.style.display = 'block'
      return
    }
    if (rateEscHiddenInput.value) {
      authorizerPermission.style.display = 'none'
    }

    // if user has permission, has escalator selected but hasnt confirmed permission, provide validation
    if (
      rateEscHiddenInput.value &&
      approvalOnBehalfBox &&
      getComputedStyle(approvalOnBehalfBox).display === 'block' &&
      approvalOnBehalf.checked == false
    ) {
      event.preventDefault()
      approvalPermission.style.display = 'block'
      return
    }
    if (approvalOnBehalf && approvalOnBehalf.checked == true) {
      approvalPermission.style.display = 'none'
    }

    const escalationForm = document.getElementById('rate-escalation-request-form')
    if (!escalationForm.checkValidity()) {
      event.preventDefault()
      const tmpSubmit = document.createElement('button')
      escalationForm.appendChild(tmpSubmit)
      tmpSubmit.click()
      escalationForm.removeChild(tmpSubmit)
    } else {
      target.classList.remove('text-white')
      target.disabled = true
      target.style.background = '#0000000f'
      target.style.color = '#9ca8b4'

      document.getElementById('rate-escalation-request-form').submit()
    }
  }

  submitShiftDetails(event) {
    document.getElementById('shift-details-form').submit()
    event.target.disabled = true
  }

  organisationCreateEdit() {
    event.preventDefault()
    const newOrganisationForm = document.querySelector('.new-organisation-form')
    const editOrganisationForm = document.querySelector('.edit-organisation-form')
    const orgNameSelector = document.getElementById('organisation[name]')
    const orgAbbreviationSelector = document.getElementById('organisation[abbreviation]')
    const orgDescSelector = document.getElementById('organisation[description]')
    const orgNameVal = document.querySelector('.modal__validation--organisation')

    const orgAbbreviationVal = document.querySelector('.modal__validation--organisation-abbreviation')

    const orgDescVal = document.querySelector('.modal__validation--organisation-desc')

    // Fraud Declaration ------------------------------

    const fraudDeclarationToggle = document.getElementById('uses_fraud_declaration_statement')
    const fraudDeclarationStatement = document.getElementById('fraud_declaration_statement')

    // If Toggle in on & statement is empty
    if (fraudDeclarationToggle.checked === true) {
      if (fraudDeclarationStatement.value === '') {
        alert('Please enter a fraud declaration statement')
        return
      }
    }

    // End --------------------------------------------

    if (orgNameSelector.value === '') {
      event.preventDefault()
      orgNameVal.style.display = 'block'
    } else {
      orgNameVal.style.display = 'none'
    }

    if (orgAbbreviationSelector.value === '') {
      event.preventDefault()
      orgAbbreviationVal.style.display = 'block'
    } else {
      orgAbbreviationVal.style.display = 'none'
    }

    if (orgDescSelector.value === '') {
      event.preventDefault()
      orgDescVal.style.display = 'block'
    } else {
      orgDescVal.style.display = 'none'
    }

    if (orgNameSelector.value === '' || orgDescSelector.value === '') {
    } else {
      newOrganisationForm ? newOrganisationForm.submit() : editOrganisationForm.submit()
    }
  }

  submitRateEscalation() {
    const reasons = this.reasonsTarget
    const reasonsValidation = document.querySelector('.modal__rate-escalation-card--reasons')
    const reasonsLengthValidation = document.querySelector('.modal__rate-escalation-card--reasons-length')
    const rateEscHiddenInput = document.querySelector('.rate-esc-hidden')
    const authorizerPermission = document.querySelector('.modal__validation--permissions')

    // if reasons exists is empty, provide validation
    if (reasons.value.length <= 0) {
      event.preventDefault()
      reasonsLengthValidation.style.display = 'none'
      reasonsValidation.style.display = 'block'
    } else if (reasons.value.length > 254) {
      event.preventDefault()
      reasonsValidation.style.display = 'none'
      reasonsLengthValidation.style.display = 'block'
    } else {
      reasonsValidation.style.display = 'none'
      reasonsLengthValidation.style.display = 'none'
    }

    // if self authorisation isn't selected and no escalator selected, provide validation
    if (rateEscHiddenInput.value === '') {
      event.preventDefault()
      authorizerPermission.style.display = 'block'
      return
    }
    if (rateEscHiddenInput.value) {
      authorizerPermission.style.display = 'none'
    }

    const escalationForm = document.getElementById('escalation-form')
    if (!escalationForm.checkValidity()) {
      const tmpSubmit = document.createElement('button')
      escalationForm.appendChild(tmpSubmit)
      tmpSubmit.click()
      escalationForm.removeChild(tmpSubmit)
    } else {
      document.getElementById('escalation-form').submit()
    }
  }

  cancelBookingForm(event) {
    event.preventDefault()
    const reasonSelectorVal = document.querySelector('.modal__validation--reasons')
    const reasonSelector = document.querySelector('#reason-selector')

    if (reasonSelector.value === '') {
      event.preventDefault()
      reasonSelectorVal.style.display = 'block'
      return
    }
    reasonSelectorVal.style.display = 'none'

    const reasonCommentVal = document.querySelector('.modal__validation--reason-comment')
    const reasonComment = document.querySelector('#reason-cancellation-comment')

    if (reasonComment.value === '') {
      event.preventDefault()
      reasonCommentVal.style.display = 'block'
      return
    }
    reasonCommentVal.style.display = 'none'

    const cancelForm = document.querySelector('#cancel-booking-form')
    if (!cancelForm.checkValidity()) {
      const tmpSubmit = document.createElement('button')
      cancelForm.appendChild(tmpSubmit)
      tmpSubmit.click()
      cancelForm.removeChild(tmpSubmit)
    } else {
      document.getElementById('cancel-booking-form').submit()
    }
  }

  bulkCancelBookingForm(event) {
    event.preventDefault()
    const reasonSelectorVal = document.querySelector('.modal__validation--reasons')
    const reasonSelector = document.querySelector('#reason-selector')

    if (reasonSelector.value === '') {
      event.preventDefault()
      reasonSelectorVal.style.display = 'block'
      return
    }
    reasonSelectorVal.style.display = 'none'

    const reasonCommentVal = document.querySelector('.modal__validation--reason-comment')
    const reasonComment = document.querySelector('#reason-cancellation-comment')

    if (reasonComment.value === '') {
      event.preventDefault()
      reasonCommentVal.style.display = 'block'
      return
    }
    reasonCommentVal.style.display = 'none'

    const cancelForm = document.querySelector('#bulk-cancel-booking-form')

    if (!cancelForm.checkValidity()) {
      const tmpSubmit = document.createElement('button')
      cancelForm.appendChild(tmpSubmit)
      tmpSubmit.click()
      cancelForm.removeChild(tmpSubmit)
    } else {
      document.getElementById('bulk-cancel-booking-form').submit()
    }
  }

  getShiftIds() {
    const checkedArray = Array.from(
      document.querySelectorAll('.custom-control-input:checked:not(#customSwitch2):not(#selectAllCheckbox)')
    ).map((item) => item.value)
    return checkedArray
  }

  bulkAssignAgencyWorkerSessional() {
    const checkedArray = this.getShiftIds()
    const getShiftIds = this.getSessionalShiftIdsTarget
    getShiftIds.value = checkedArray

    const bulkAssignAgencyWorkerSessional = document.getElementById('bulk-agency-info-form-sessional-rate')
    const agencySelector = document.querySelector('.agency-selector')
    const agencyWorkerSelector = document.querySelector('.agency-worker-picker')

    // Validation boxes
    const agencyForBookingVal = document.querySelector('.modal__validation--agency-sessional')
    const workerForBookingVal = document.querySelector('.modal__validation--worker-sessional')

    // ----- //

    if (
      agencySelector.value === '' ||
      agencySelector.value === 'Select agency' ||
      agencySelector.value === 'undefined'
    ) {
      event.preventDefault()
      agencyForBookingVal.style.display = 'block'
      return
    }
    event.preventDefault()
    agencyForBookingVal.style.display = 'none'

    if (
      agencyWorkerSelector.value === '' ||
      agencyWorkerSelector.value === 'Select worker' ||
      agencyWorkerSelector.value === 'undefined'
    ) {
      event.preventDefault()
      workerForBookingVal.style.display = 'block'
      return
    }
    event.preventDefault()
    workerForBookingVal.style.display = 'none'

    if (!bulkAssignAgencyWorkerSessional.checkValidity()) {
      const tmpSubmit = document.createElement('button')
      bulkAssignAgencyWorkerSessional.appendChild(tmpSubmit)
      tmpSubmit.click()
      bulkAssignAgencyWorkerSessional.removeChild(tmpSubmit)
    } else {
      document.getElementById('bulk-agency-info-form-sessional-rate-form').submit()
    }
  }

  bulkAssignAgencyWorkerSingleRate() {
    const checkedArray = this.getShiftIds()
    const getShiftIds = this.getSingleShiftIdsTarget
    getShiftIds.value = checkedArray

    const bulkAssignAgencyWorkerSingleRate = document.getElementById('bulk-agency-info-form-single-rate')
    const agencySelector = document.querySelector('.agency-selector')
    const agencyWorkerSelector = document.querySelector('.agency-worker-picker')

    // Validation boxes
    const agencyForBookingVal = document.querySelector('.modal__validation--agency-single')
    const workerForBookingVal = document.querySelector('.modal__validation--worker-single')

    console.log(agencyForBookingVal.value, workerForBookingVal.value)

    // ----- //

    if (
      agencySelector.value === '' ||
      agencySelector.value === 'Select agency' ||
      agencySelector.value === 'undefined'
    ) {
      event.preventDefault()
      agencyForBookingVal.style.display = 'block'
      return
    }
    event.preventDefault()
    agencyForBookingVal.style.display = 'none'

    if (
      agencyWorkerSelector.value === '' ||
      agencyWorkerSelector.value === 'Select worker' ||
      agencyWorkerSelector.value === 'undefined'
    ) {
      event.preventDefault()
      workerForBookingVal.style.display = 'block'
      return
    }
    event.preventDefault()
    workerForBookingVal.style.display = 'none'

    if (!bulkAssignAgencyWorkerSingleRate.checkValidity()) {
      const tmpSubmit = document.createElement('button')
      bulkAssignAgencyWorkerSingleRate.appendChild(tmpSubmit)
      tmpSubmit.click()
      bulkAssignAgencyWorkerSingleRate.removeChild(tmpSubmit)
    } else {
      document.getElementById('bulk-agency-info-form-single-rate').submit()
    }
  }

  bulkAssignAgencyWorkerSplitRate() {
    const checkedArray = this.getShiftIds()
    const getShiftIds = this.getSplitShiftIdsTarget
    getShiftIds.value = checkedArray

    const bulkAssignAgencyWorkerSplitRate = document.getElementById('bulk-agency-info-form-split-rate')
    const agencySelector = document.querySelector('.agency-selector-split')
    const agencyWorkerSelector = document.querySelector('.agency-worker-picker-split')

    // Validation boxes
    const agencyForBookingVal = document.querySelector('.modal__validation--agency-split')
    const workerForBookingVal = document.querySelector('.modal__validation--worker-split')

    // ----- //

    if (
      agencySelector.value === '' ||
      agencySelector.value === 'Select agency' ||
      agencySelector.value === 'undefined'
    ) {
      event.preventDefault()
      agencyForBookingVal.style.display = 'block'
      return
    }
    event.preventDefault()
    agencyForBookingVal.style.display = 'none'

    if (
      agencyWorkerSelector.value === '' ||
      agencyWorkerSelector.value === 'Select worker' ||
      agencyWorkerSelector.value === 'undefined'
    ) {
      event.preventDefault()
      workerForBookingVal.style.display = 'block'
      return
    }
    event.preventDefault()
    workerForBookingVal.style.display = 'none'

    if (!bulkAssignAgencyWorkerSplitRate.checkValidity()) {
      const tmpSubmit = document.createElement('button')
      bulkAssignAgencyWorkerSplitRate.appendChild(tmpSubmit)
      tmpSubmit.click()
      bulkAssignAgencyWorkerSplitRate.removeChild(tmpSubmit)
    } else {
      document.getElementById('bulk-agency-info-form-split-rate').submit()
    }
  }

  bulkCancelAgencyBookingForm() {
    document.getElementById('bulk-cancel-agency-booking-form').submit()
  }

  approveApplication() {
    const loading = `<svg
                        width="38"
                        height="38"
                        viewBox="0 0 38 38"
                        xmlns="http://www.w3.org/2000/svg"
                        stroke="#ff7f4d"
                      >
                        <g fill="none" fillRule="evenodd">
                          <g transform="translate(1 1)" strokeWidth="2">
                            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                              <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="0 18 18"
                                to="360 18 18"
                                dur="1s"
                                repeatCount="indefinite"
                              />
                            </path>
                          </g>
                        </g>
                      </svg>`

    const oParser = new DOMParser().parseFromString(loading, 'image/svg+xml')
    const loading_element = oParser.documentElement
    const approve_button = document.getElementsByClassName('approve_button')[0]

    approve_button.setAttribute('disabled', true)
    approve_button.insertAdjacentHTML('afterEnd', loading_element.outerHTML)
    document.getElementById('applications-form').submit()
  }

  assignWorker() {
    event.preventDefault()
    const workerVal = document.querySelector('.modal__validation--worker')
    const { value } = document.querySelector('#assign-worker-id')

    if (value == '') {
      console.log('Please select a worker...')
      return
    }

    const agencyBooked = document.querySelector('#assign-worker-agency-shift').value
    const shiftId = document.querySelector('#assign-worker-shift-id').value
    const safetyCheckEnabled = document.querySelector('#safety-check-enabled-result').value

    const assignWorkerForm = document.createElement('form')
    assignWorkerForm.method = 'post'
    if (shiftId) {
      assignWorkerForm.setAttribute(
        'action',
        `/shifts/${shiftId}/assign_worker?shift[worker_id]=${value}&shift[safety_compliant]=${true}`
      )
      assignWorkerForm.innerHTML += '<input name="_method" type="hidden" value="patch" />'
    }
    assignWorkerForm.style.display = 'hidden'
    assignWorkerForm.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(assignWorkerForm)

    console.log('AssignWorker:', value)
    console.log('ShiftIds:', shiftId)
    console.log('WorkerVAL', workerVal)
    console.log('AgencyBooked:', agencyBooked)
    console.log('SafteyCheck:', safetyCheckEnabled)

    if (safetyCheckEnabled === 'true') {
      const safetyApiData = {
        shift_id: shiftId,
        worker_ids: [value],
      }

      const safetyUrl =
        // Production
        window.location.hostname.match('api.patchwork.health')
          ? 'https://safetychecks.patchwork.health/v1/worker_status'
          : // Api-test
          window.location.hostname.match('api-test.patchwork.health')
          ? 'https://staging-safetychecks.patchwork.health/v1/worker_status'
          : // Local host
            `//${window.location.hostname}:8888/v1/worker_status`
      fetch(safetyUrl, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(safetyApiData),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data)

          if (!data[0].safe) {
            // Assign Worker flagged as unsafe show user hidden warning screen....
            document.getElementById('assign-worker-form').style.display = 'none'
            document.getElementById('safety__modal__wrapper').style.display = 'block'
            return
          }

          // Worker is safe, continue as normal...
          this.processAssignWorker(value, agencyBooked, workerVal, assignWorkerForm)
        })
        .catch((err) => {
          alert('There was an Error when checking safety checks compatibility:', err)
        })
    } else {
      this.processAssignWorker(value, agencyBooked, workerVal, assignWorkerForm)
    } // end of safetyCheckEnabled if block
  }

  processAssignWorker(value, agencyBooked, workerVal, assignWorkerForm) {
    if (value) {
      if (agencyBooked) {
        workerVal.style.display = 'none'
        if (confirm('Are you sure you wish to proceed? This will override the existing agency booking.') == true) {
          assignWorkerForm.submit()
        }
      } else {
        console.log('hello')
        workerVal.style.display = 'none'
        assignWorkerForm.submit()
      }
    } else {
      workerVal.style.display = 'block'
    }
  }

  /*
    =======================================
    START - SAFETY CHECKS FUNCTIONS FOR HUB
    =======================================
  */

  /* 1. Modal Assign Reject - Go back to user List */

  ModalSafetyReject() {
    document.getElementById('assign-worker-form').style.display = 'block'
    document.getElementById('safety__modal__wrapper').style.display = 'none'
    document.querySelector('#assign-worker-id').value = ''

    const elems = document.querySelectorAll('.safety__selected__btn')

    for (let x = 0; x < elems.length; x++) {
      elems[x].innerHTML = 'Select'
    }
  }

  /* 2. Modal Assign Assign - User is givin a warning but accept them anyway */

  ModalSafetyAssign() {
    event.preventDefault()
    const workerVal = document.querySelector('.modal__validation--worker')
    const { value } = document.querySelector('#assign-worker-id')

    if (value == '') {
      console.log('Please select a worker...')
      return
    }

    const agencyBooked = document.querySelector('#assign-worker-agency-shift').value
    const shiftId = document.querySelector('#assign-worker-shift-id').value

    const assignWorker = document.createElement('form')
    assignWorker.method = 'post'
    if (shiftId) {
      assignWorker.setAttribute(
        'action',
        `/shifts/${shiftId}/assign_worker?shift[worker_id]=${value}&shift[safety_compliant]=${false}`
      )
      assignWorker.innerHTML += '<input name="_method" type="hidden" value="patch" />'
    }
    assignWorker.style.display = 'hidden'
    assignWorker.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(assignWorker)

    if (value) {
      if (agencyBooked) {
        workerVal.style.display = 'none'
        if (confirm('Are you sure you wish to proceed? This will override the existing agency booking.') == true) {
          assignWorker.submit()
        }
      } else {
        workerVal.style.display = 'none'
        assignWorker.submit()
      }
    } else {
      workerVal.style.display = 'block'
    }
  }

  /* 3. ActionBar Safety Reject - User is rejects a user on actionbar */
  ActionSafetyReject() {
    document.getElementById('action__bar__safety').style.display = 'block'
    document.getElementById('safety__action__wrapper').style.display = 'none'
    document.querySelector('#assign-worker-id').value = ''

    const elems = document.querySelectorAll('.safety__selected__btn')

    for (let x = 0; x < elems.length; x++) {
      elems[x].innerHTML = 'Select'
    }
  }

  /* 4. Actionbar Assign - User is givin a warning but accept them anyway */

  ActionSafetyAssign() {
    event.preventDefault()

    const { value } = document.querySelector('#assign-worker-id')

    if (value == '') return

    const shiftIds = document.getElementsByClassName('action__bar__safety')[0]
    const shiftIdLength = shiftIds.value.split(',').length

    if (shiftIdLength == 1) {
      console.log('We have 1 shift to assign workers to')
    } else if (shiftIdLength > 1) {
      console.log("Multiple shifts let's skip safety checks for now...")
    } else {
      console.log(3)
    }

    const workerVal = document.querySelector('.modal__validation--worker')

    const assignWorker = document.createElement('form')
    assignWorker.setAttribute('method', 'post')
    assignWorker.setAttribute(
      'action',
      `/bulk_assign_worker/?${this.shiftQuery()}&shift[worker_id]=${value}&shift[safety_compliant]=${false}`
    )
    assignWorker.style.display = 'hidden'
    assignWorker.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(assignWorker)

    if (value) {
      workerVal.style.display = 'none'
      assignWorker.submit()
    } else {
      workerVal.style.display = 'block'
    }
  }

  // Application - go back to applicants *********

  ApplicationBack(event) {
    event.preventDefault()

    console.log("Let's go back..")

    const selectBtns = Array.from(document.querySelectorAll('.applicant-btn'))
    selectBtns.forEach(function (selectBox, i) {
      selectBox.innerText = 'Select'
    })

    document.getElementById('application__safety__ul').style.display = 'block'
    document.getElementById('application__safety__hidden').style.display = 'none'
  }

  /*
    =======================================
      END - SAFETY CHECKS FUNCTIONS FOR HUB
    =======================================
  */

  agencyForm() {
    event.preventDefault()
    const newAgencyForm = document.querySelector('.new-agency-form')
    const editAgencyForm = document.querySelector('.edit-agency-form')

    const titleSelector = document.getElementById('agency[title]')
    const titleVal = document.querySelector('.modal__validation--agency-title')

    if (titleSelector.value === '') {
      event.preventDefault()
      titleVal.style.display = 'block'
    } else {
      titleVal.style.display = 'none'
    }

    if (titleSelector.value === '') {
    } else {
      newAgencyForm ? newAgencyForm.submit() : editAgencyForm.submit()
    }
  }

  /* Submit Sign Off Form -------------------------------------------- */
  signOffForm(event) {
    /*
   If Fraud Declaration statement is active & shift .match 'to_sign_off' status)
   then we require hubUser to accept the statement before submitting the form,
   else, proceed as normal...
  */

    const requiresHubUserFD = document.getElementById('fd__hubuser__statement')
    const fdCheckbox = document.getElementById('fd__hubUser__checkbox').checked

    if (requiresHubUserFD && !fdCheckbox) {
      alert('Please read & accept the Fraud Declaration Statement')
      event.preventDefault()
    } else {
      document.getElementById('sign-off-form').submit()
      event.target.disabled = true
    }
  }

  agencyInfoFormSessional() {
    document.getElementById('agency-info-form-sessional').submit()
  }

  agencyInfoFormSingleRate() {
    const agencyInfoFormSingleRate = document.getElementById('agency-info-form-single-rate')
    const agencySelector = document.querySelector('.agency-selector')
    const agencyWorkerSelector = document.querySelector('.agency-worker-picker')

    // Validation boxes
    const agencyForBookingVal = document.querySelector('.modal__validation--agency-single')
    const workerForBookingVal = document.querySelector('.modal__validation--worker-single')

    // ----- //

    if (
      agencySelector.value === '' ||
      agencySelector.value === 'Select agency' ||
      agencySelector.value === 'undefined'
    ) {
      event.preventDefault()
      agencyForBookingVal.style.display = 'block'
      return
    }
    event.preventDefault()
    agencyForBookingVal.style.display = 'none'

    if (
      agencyWorkerSelector.value === '' ||
      agencyWorkerSelector.value === 'Select worker' ||
      agencyWorkerSelector.value === 'undefined'
    ) {
      event.preventDefault()
      workerForBookingVal.style.display = 'block'
      return
    }
    event.preventDefault()
    workerForBookingVal.style.display = 'none'

    if (!agencyInfoFormSingleRate.checkValidity()) {
      const tmpSubmit = document.createElement('button')
      agencyInfoFormSingleRate.appendChild(tmpSubmit)
      tmpSubmit.click()
      agencyInfoFormSingleRate.removeChild(tmpSubmit)
    } else {
      document.getElementById('agency-info-form-single-rate').submit()
    }
  }

  agencyInfoFormSplitRate() {
    const agencyInfoFormSplitRate = document.getElementById('agency-info-form-split-rate')
    const agencySelectorSplit = document.querySelector('.agency-selector-split')
    const agencyWorkerSelectorSplit = document.querySelector('.agency-worker-picker-split')

    // Validation boxes
    const agencyForBookingSplitVal = document.querySelector('.modal__validation--agency-split')
    const workerForBookingSplitVal = document.querySelector('.modal__validation--worker-split')

    // ----- //

    if (agencySelectorSplit.value === '') {
      event.preventDefault()
      agencyForBookingSplitVal.style.display = 'block'
      return
    }
    event.preventDefault()
    agencyForBookingSplitVal.style.display = 'none'

    if (
      agencyWorkerSelectorSplit.value === '' ||
      agencyWorkerSelectorSplit.value === 'Select worker' ||
      agencyWorkerSelectorSplit.value === 'undefined'
    ) {
      event.preventDefault()
      workerForBookingSplitVal.style.display = 'block'
      return
    }
    event.preventDefault()
    workerForBookingSplitVal.style.display = 'none'

    if (!agencyInfoFormSplitRate.checkValidity()) {
      const tmpSubmit = document.createElement('button')
      agencyInfoFormSplitRate.appendChild(tmpSubmit)
      tmpSubmit.click()
      agencyInfoFormSplitRate.removeChild(tmpSubmit)
    } else {
      document.getElementById('agency-info-form-split-rate').submit()
    }
  }

  submitBookingPreferences() {
    document.getElementById('booking-preferences-form').submit()
  }

  submitSuspendWorker() {
    const suspendWorkerForm = document.getElementById('suspend-worker')

    if (!suspendWorkerForm.checkValidity()) {
      const tmpSubmit = document.createElement('button')
      suspendWorkerForm.appendChild(tmpSubmit)
      tmpSubmit.click()
      suspendWorkerForm.removeChild(tmpSubmit)
    } else {
      this.submitSuspendButtonTarget.classList.add('d-none')
      document.getElementById('suspend-worker').submit()
    }
  }

  submitEmploymentDetails() {
    document.getElementById('worker-form').submit()
  }

  submitCovidDetails() {
    document.getElementById('worker-covid-form').submit()
  }

  submitCollaborativeBank() {
    const workerForm = document.getElementById('collaborative-bank-form')
    const organisationSelector = document.querySelector('#organisation-selector')

    // Validation boxes
    const organisationSelectorVal = document.querySelector('.modal__validation--organisation')

    if (organisationSelector.value === '') {
      event.preventDefault()
      organisationSelectorVal.style.display = 'block'
      return
    }

    if (!workerForm.checkValidity()) {
      const tmpSubmit = document.createElement('button')
      workerForm.appendChild(tmpSubmit)
      tmpSubmit.click()
      workerForm.removeChild(tmpSubmit)
    } else {
      document.getElementById('worker-form').submit()
    }
  }
}

application.register('modal', ModalController)
